<template>
  <div class="blog">
    <div class="content">
      <div class="row">
        <div class="col">
          <router-link class="w-full vave-btn btn-blue" :to="'/blog/create'">New Article</router-link>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="table-container">
            <table class="table">
              <thead class="head">
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(post, key) in posts.data" :key="key" :class="{'grayed': post.status == 'draft'}">
                  <td>
                    <div v-if="post.media_url" class="img" :style="'background-image:url('+image(post.media_url, 120)+');'"></div>
                    <img v-else :src="placeholder" class="img" style="pointer-events:none;opacity: 0.2;" />
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span :class="{'pale': post.status == 'draft'}">{{ post.title }}</span>
                      <span class="pale" v-if="post.status != 'draft'">{{ formatValue(post.published_at, 'fullDate') }}</span>
                    </div>
                  </td>
                  <td :class="{'pale': post.status == 'draft'}">{{ post.status }}</td>

                  <td>
                    <div>
                      <router-link class="w-full vave-btn" :to="'/blog/' + post.id + '/edit'">
                        <i class="fa-regular fa-pen-to-square me-1"></i>
                        Edit
                      </router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination class="paginator" :limit="4" align="center" :data="posts" @pagination-change-page="getPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../http.js";
import imageProxy from "./../imageProxy";
import pagination from "laravel-vue-pagination";
import { formatValue } from "./../formatters";

export default {
  data() {
    return {
      posts: {},
      placeholder: require("./../assets/product-image-placeholder.png"),
    };
  },
  components: {
    pagination,
  },
  watch: {
    $route() {
      this.getPosts();
    },
  },
  mounted() {
    this.getPosts();
  },
  computed: {
    prox() {
      return process.env.VUE_APP_IMAGE_URL;
    },
  },
  methods: {
    formatValue,
    image: imageProxy.image,
    showArticle(id) {
      this.$refs["articleInspector-" + id][0].show();
    },
    getPage(page) {
      this.$router.push("/blog?page=" + page);
    },
    getPosts() {
      let page = this.$route.query.page ? this.$route.query.page : 1;
      this.posts = {};
      axios.get("/api/admin/posts?per_page=" + 10 + "&page=" + page).then(
        (r) => {
          if (r.status == 200) {
            this.posts = r.data;
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
  },
};
</script>

<style lang="scss">
.blog {
  .grayed {
    background: #f5f5f5;
  }
  td {
    vertical-align: middle;
  }
  .img {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20%;
  }
}
</style>
